<template>
  <div>
    <c-search-box @enter="getTypeList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="edit" name="plantCd" :required="true" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="typegrid"
          title="평가항목종류"
          tableId="typegrid"
          :columns="typegrid.columns"
          :data="typegrid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="LBLSEARCH" icon="search"  @btnClicked="getTypeList"/>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemgrid"
          title="평가항목"
          tableId="itemgrid"
          :columns="itemgrid.columns"
          :data="itemgrid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
        >
        <!-- selection="multiple"
          rowKey="mdmVendorEvaluationItemId" -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addrow" v-if="editable && isSelectGrp" />
              <!-- <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow" v-if="editable && isSelectGrp" /> -->
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-Assess-item',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y'
      },
      typegrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '평가항목종류',
            align: 'left',
            sortable: false,
          }
        ],
        data: [],
      },
      itemgrid: {
        columns: [
          {
            name: 'itemNm',
            field: 'itemNm',
            label: '평가항목명',
            align: 'left',
            type: 'text',
            style: 'width:400px',
            sortable: false,
            required: true,
          },
          {
            name: 'scoring',
            field: 'scoring',
            label: '배점',
            align: 'center',
            type: 'number',
            style: 'width:50px',
            sortable: false,
            required: true,
          },
          {
            name: 'standardScore',
            field: 'scoring',
            label: '기준점수',
            child: [
              {
                name: 'scoreGreat',
                field: 'scoreGreat',
                label: '우수',
                align: 'center',
                type: 'number',
                style: 'width:50px',
                sortable: false,
              },
              {
                name: 'scoreCommonly',
                field: 'scoreCommonly',
                label: '보통',
                align: 'center',
                type: 'number',
                style: 'width:50px',
                sortable: false,
              },
              {
                name: 'scoreInadequate',
                field: 'scoreInadequate',
                label: '미흡',
                align: 'center',
                type: 'number',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'evaluationCriteria',
            field: 'evaluationCriteria',
            label: '평가기준',
            align: 'center',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'orderNo',
            field: 'orderNo',
            label: '정렬순서',
            align: 'center',
            type: 'text',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSelectGrp: false,
      selectedGroupCd: '',
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'searchParam.plantCd': function() {
      this.itemgrid.data = [];
      this.rowRemoveSelect();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.cim.vendor.item.list.url;
      this.saveUrl = transactionConfig.mdm.cim.vendor.item.save.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.item.delete.url;
      // code setting
      // list setting
      this.getTypeList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
      this.selectedGroupCd = '';
    },
    rowClick(row) {
      this.getItemList(row);
    },
    getTypeList() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      if (this.searchParam.plantCd) {
        this.$comm.getComboItems('VENDOR_EVALUATION_TYPE_CD', null, 'Y').then(_result => {
          this.typegrid.data = _result;
        });
      } else {
        this.typegrid.data = [];
      }
    },
    getItemList(_row) {
      if (this.searchParam.plantCd.indexOf(',') < 0) {
        this.isSelectGrp = true;
        this.selectedGroupCd = _row.code;
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.searchParam.plantCd,
          useFlag: this.searchParam.useFlag,
          vendorEvaluationTypeCd: _row.code,
        }
        this.$http.request((_result) => {
          this.itemgrid.data = _result.data;
        },
        () => {
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '사업장을 선택하세요',
          type: 'warning', // success / info / warning / error
        });
        this.itemgrid.data = [];
      }
    },
    addrow() {
      this.itemgrid.data.push({
        plantCd: this.searchParam.plantCd,  // 사업장코드
        mdmVendorEvaluationItemId: uid(),  // 평가항목 일련번호
        vendorEvaluationTypeCd: this.selectedGroupCd,  // 평가항목 종류코드
        itemNm: '',  // 평가항목명
        scoring: null,  // 배점
        scoreGreat: null,  // 우수 기준점수
        scoreCommonly: null,  // 보통 기준점수
        scoreInadequate: null,  // 미흡 기준점수
        evaluationCriteria: '',  // 평가기준
        orderNo: this.itemgrid.data.length + 1,  // 정렬순서
        useFlag: 'Y',  // 사용여부
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId
      })
    },
    saveMst() {
      if (this.$comm.validTable(this.itemgrid.columns, this.itemgrid.data)) {
        let saveData = this.itemgrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getItemList({code: saveData[0].vendorEvaluationTypeCd});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    // removeRow() {
    //   let selectData = this.$refs['itemgrid'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: 'LBLCONFIRM',
    //       message: 'MSGREMOVE', // 삭제하시겠습니까?,
    //       type: 'warning', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.$format(this.deleteUrl);
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(selectData)
    //         };
    //         this.$http.request(() => {
    //           // this.getItemList({code: selectData[0].codeGrpCd});
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
              
    //           this.$_.forEach(selectData, item => {
    //             this.itemgrid.data = this.$_.reject(this.itemgrid.data, item);
    //           })
    //           this.$refs['itemgrid'].$refs['compo-table'].clearSelection();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
  }
};
</script>
